import { createStyles, Drawer, Link as MaterialLink, List, ListItem, makeStyles, Theme } from "@material-ui/core";
import LayoutContext from "components/Layout/LayoutContext";
import PagesContext from "components/Pages/PagesContext";
import { primaryColor } from "library/Colors";
import { HomeRoutePath, NossasUnidadesRoutePath, OrientacoesExamesUrl, QuemSomosRoutePath, ResultadosExamesUrl } from "library/Routes";
import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import LeftMenuSubHeader from './LeftMenuSubHeader';

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      menuItem: {
        color: primaryColor,
        fontFamily: '"HK Nova Medium", sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '19px',
        textDecoration: 'none !important',
        padding: theme.spacing(2, 3)
      }
    }),
);

const LeftMenu = () => {
  const {isXsSize, isSmSize, leftMenuOpened, setLeftMenuOpened} = useContext(LayoutContext);
  const {toHome, toQuemSomos, toNossasUnidades} = useContext(PagesContext);
  const {t} = useTranslation();
  const classes = useStyles();

  const toClose = useCallback(() => {
    setLeftMenuOpened(false);
  }, [setLeftMenuOpened])

  return isSmSize || isXsSize ? (
    <Drawer
      anchor="left"
      variant="temporary"
      open={leftMenuOpened}
      onClose={() => setLeftMenuOpened(false)}
    >
      <List component="nav" subheader={<LeftMenuSubHeader toClose={toClose} />}>
        <ListItem
          component={Link}
          to={HomeRoutePath}
          onClick={(e: any) => {
            e.preventDefault();
            toClose();
            toHome();
          }}
          className={classes.menuItem}
        >
          {t(`menu.home`)}
        </ListItem>
        <ListItem
          component={Link}
          to={QuemSomosRoutePath}
          onClick={(e: any) => {
            e.preventDefault();
            toClose();
            toQuemSomos();
          }}
          className={classes.menuItem}
        >
          {t(`menu.quemSomos`)}
        </ListItem>
        <ListItem
          component={MaterialLink}
          href={OrientacoesExamesUrl}
          target="_blank"
          onClick={toClose}
          className={classes.menuItem}
        >
          {t(`menu.orientacoesExames`)}
        </ListItem>
        <ListItem
          component={MaterialLink}
          href={ResultadosExamesUrl}
          target="_blank"
          onClick={toClose}
          className={classes.menuItem}
        >
          {t(`menu.resultadoExames`)}
        </ListItem>
        <ListItem
          component={Link}
          to={NossasUnidadesRoutePath}
          onClick={(e: any) => {
            e.preventDefault();
            toClose();
            toNossasUnidades();
          }}
          className={classes.menuItem}
        >
          {t(`menu.nossasUnidades`)}
        </ListItem>
      </List>
    </Drawer>
  ) : null;
}

export default LeftMenu;