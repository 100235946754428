import { createStyles, makeStyles, Theme } from "@material-ui/core";

export default makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        width: `${89 * 1 / 48}em`,
        height: '1em'
      },
    }),
);