import React from 'react';
import PreparoExamesIcon from 'components/Icons/PreparoExamesIcon'
import ResultadoExamesIcon from 'components/Icons/ResultadoExamesIcon'
import { Link, Button, Container, createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { coralColor, secondaryColor, whiteColor } from 'library/Colors';
import { OrientacoesExamesUrl, ResultadosExamesUrl } from 'library/Routes';

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        position: 'relative',
        display: "flex",
        width: '100%',
        paddingBottom: theme.spacing(4),
      },
      title: {
        fontFamily: '"HK Nova Medium", sans-serif',
        fontSize: '28px',
        fontWeight: 600,
        textAlign: 'center',
        width: '100%',
      },
      blockContent: {
        display: 'flex',
        flexDirection: 'row',
        paddingTop: theme.spacing(3),
        [theme.breakpoints.up('md')]: {
          '&:first-child': {
            justifyContent: 'flex-end',
          }
        },
        [theme.breakpoints.down('sm')]: {
          justifyContent: 'center',
        },
        [theme.breakpoints.down('xs')]: {
          width: '100%',
          flexDirection: 'column',
        },
      },
      iconContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minWidth: '150px',
      },
      icon: {
        fontSize: '100px',
      },
      textContent: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(0, 6, 0, 3),
        alignItems: 'flex-start',
        [theme.breakpoints.down('xs')]: {
          padding: theme.spacing(0, 3),
          textAlign: 'center',
          alignItems: 'center',
        }
      },
      blockTitle: {
        fontFamily: '"HK Nova Medium", sans-serif',
        fontSize: '21spx',
        fontWeight: 600,
        width: '100%',
      },
      blockText: {
        marginTop: theme.spacing(2.5),
        fontSize: '16px',
        fontWeight: 500,
      },
      button: {
        backgroundImage: `linear-gradient(226.03deg, ${coralColor} 4.7%, ${secondaryColor} 96.58%)`,
        borderRadius: '8px',
        fontSize: '14px',
        color: whiteColor,
        textTransform: 'initial',
        lineHeight: '29px',
        marginTop: theme.spacing(2),
        padding: theme.spacing(0, 1),
        boxShadow: 'none !important',
        textDecoration: 'none !important',
      }
    }),
);

interface Props {
  className?: string;
}

const NossosServicos = ({
  className,
}: Props) => {
  const {t} = useTranslation();
  const classes = useStyles();
  return (
    <div className={[
      classes.root,
      className || ''
    ].join(' ')}>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography component="h5" color="primary" className={classes.title}>
                  {t(`Home.NossosServicos.title`)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item sm={12} md={6} className={classes.blockContent}>
                <div className={classes.iconContent}>
                  <PreparoExamesIcon className={classes.icon} />
                </div>
                <div className={classes.textContent}>
                  <Typography component="h6" color="primary" className={classes.blockTitle}>
                    <span dangerouslySetInnerHTML={{ __html: t(`Home.NossosServicos.preparoExames.title`) }} />
                  </Typography>
                  {/* <Typography component="p" color="primary" className={classes.blockText}>
                    {t(`Home.NossosServicos.preparoExames.text`)}
                  </Typography> */}
                  <Link
                    className={classes.button}
                    component={Button}
                    // variant="contained"
                    href={OrientacoesExamesUrl}
                    target="_blank"
                  >
                    {t(`Home.NossosServicos.preparoExames.buttonLabel`)}
                  </Link>
                </div>
              </Grid>
              <Grid item sm={12} md={6} className={classes.blockContent}>
                <div className={classes.iconContent}>
                  <ResultadoExamesIcon className={classes.icon} />
                </div>
                <div className={classes.textContent}>
                  <Typography component="h6" color="primary" className={classes.blockTitle}>
                    <span dangerouslySetInnerHTML={{ __html: t(`Home.NossosServicos.resultadoExames.title`) }} />
                  </Typography>
                  {/* <Typography component="p" color="primary" className={classes.blockText}>
                    {t(`Home.NossosServicos.resultadoExames.text`)}
                  </Typography> */}
                  <Link
                    className={classes.button}
                    component={Button}
                    // variant="contained"
                    href={ResultadosExamesUrl}
                    target="_blank"
                  >
                    {t(`Home.NossosServicos.resultadoExames.buttonLabel`)}
                  </Link>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default NossosServicos;