import { createContext } from "react";
import {ILayoutContext} from 'interfaces/ILayoutContext';

export default createContext({
  isXsSize: false,
  isSmSize: false,
  isMdSize: false,
  isLgSize: false,
  isXlSize: false,
  leftMenuOpened: false,
  setLeftMenuOpened: () => {},
} as ILayoutContext);