import React from 'react';
import {getUniqueKey} from 'utils/KeyUtils';
import { IconUIComponentProps, useIconStyle } from 'components/Icons/Icon';
import useStyles from './style';
import { primaryColor, whiteColor } from 'library/Colors';

interface Props extends IconUIComponentProps {
  inverted?: boolean;
}

const LogoTopLab = ({
  color,
  size,
  style,
  className,
  noFill,
  inverted
}: Props) => {
  const classes = useStyles();
  const styles = useIconStyle({ noFill, size, color });
  const gradientId1: string = getUniqueKey();
  const gradientId2: string = getUniqueKey();
  const gradientId3: string = getUniqueKey();
  const gradientId4: string = getUniqueKey();
  
  return (
    <svg
      className={[styles.icon, className, classes.root].join(' ').trim()}
      fontSize={size}
      style={style}
      viewBox="0 0 91 49"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M11.7218 22.6535V20.0042C11.7218 19.6919 11.4698 19.44 11.1575 19.44H8.11066V16.0008C8.11066 15.6885 7.85741 15.4365 7.54643 15.4365H5.17533C4.86304 15.4365 4.6111 15.6898 4.6111 16.0008V19.44H1.56423C1.25194 19.44 1 19.6932 1 20.0042V22.6535C1 22.9658 1.25325 23.2177 1.56423 23.2177H4.6111V26.695V26.6963C4.6111 26.6976 4.6111 26.6976 4.6111 26.6989V26.7763H4.61241C4.65309 30.4845 7.5543 33.4986 11.2126 33.7335C11.4816 33.6915 11.6916 33.4684 11.6916 33.1863V30.7942C11.6916 30.4819 11.4383 30.23 11.1273 30.23L11.1339 30.2077C9.44645 29.9518 8.14609 28.5255 8.10673 26.7763H8.10804V23.219H11.1549C11.4698 23.219 11.7218 22.9658 11.7218 22.6535ZM23.4368 26.5821C23.4368 28.5806 21.8163 30.2011 19.8179 30.2011C17.8194 30.2011 16.1989 28.5806 16.1989 26.5821C16.1989 24.5837 17.8194 22.9632 19.8179 22.9632C21.8176 22.9645 23.4368 24.5837 23.4368 26.5821ZM26.985 26.5821C26.985 22.6246 23.7767 19.4164 19.8192 19.4164C15.8617 19.4164 12.6521 22.6246 12.6521 26.5821C12.6521 30.5396 15.8604 33.7479 19.8179 33.7479C23.7754 33.7479 26.985 30.5396 26.985 26.5821ZM37.7828 26.4772C37.7828 28.4074 36.2476 29.9754 34.3318 30.0358V30.0331H31.9686V22.9015H34.2163V22.912C36.1872 22.912 37.7828 24.5076 37.7828 26.4772ZM41.2784 26.4772C41.2784 22.6167 38.1791 19.4807 34.3318 19.4203V19.4177H31.4909V19.4255C31.4634 19.4216 31.4345 19.4177 31.4043 19.4177H29.0319C28.7196 19.4177 28.4677 19.6709 28.4677 19.9819V39.2918C28.4677 39.6041 28.721 39.8561 29.0319 39.8561H31.4043C31.7166 39.8561 31.9686 39.6028 31.9686 39.2918V33.5275L34.2163 33.538C38.1174 33.5366 41.2784 30.3756 41.2784 26.4772Z" fill={inverted ? whiteColor : primaryColor}/>
      <rect x="45" y="3" width="43" height="43" rx="6" fill="white"/>
      <path d="M75.8608 7.65274C75.8629 7.6542 75.8636 7.65544 75.8636 7.65631Z" fill={`url(#${gradientId1})`}/>
      <path d="M65.1968 22.6062C63.2272 22.6062 61.6316 24.2018 61.6316 26.1714C61.6316 28.1016 63.1668 29.6697 65.0826 29.73V29.7261H67.7686V22.5944H65.1968V22.6062Z" fill={`url(#${gradientId2})`}/>
      <path d="M79.868 29.73C81.7838 29.6697 83.319 28.1016 83.319 26.1714C83.319 24.2018 81.7234 22.6062 79.7538 22.6062V22.5957H77.1098V29.7274H79.8693L79.868 29.73Z" fill={`url(#${gradientId3})`}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M89.7134 11.3963V37.6608C89.7134 43.4015 85.0578 48.0571 79.3157 48.0571H53.0526C47.3105 48.0571 42.6562 43.4029 42.6562 37.6608V11.3963C42.6562 5.65428 47.3105 1 53.0526 1H79.317C85.0578 1 89.7134 5.65428 89.7134 11.3963ZM75.8608 7.65274C75.8607 7.65267 75.8609 7.6528 75.8608 7.65274C75.6863 7.46641 75.559 7.14755 75.5787 6.94023C75.5787 6.37599 76.0287 5.91936 76.589 5.9023C76.5893 5.90229 76.5901 5.90228 76.5903 5.90228H83.9109C84.4201 5.91933 84.8321 6.34054 84.8321 6.8431V14.1913C84.8269 14.7399 84.3832 15.186 83.8382 15.1949C83.8314 15.1951 83.8246 15.1951 83.8178 15.1951C83.5541 15.1951 83.3139 15.0941 83.1342 14.9287L75.8634 7.65796C75.8634 7.65796 75.864 7.65741 75.8636 7.65631C75.8634 7.65571 75.863 7.65495 75.8621 7.65403C75.8618 7.65366 75.8611 7.6531 75.8608 7.65274ZM56.7778 33.2309C57.1072 33.2309 57.3749 32.9632 57.3749 32.6339H57.3762V30.0344C57.3762 29.7116 57.1151 29.4505 56.7923 29.4505H50.0333V14.9628C50.0333 14.6335 49.7656 14.3658 49.4362 14.3658H46.8355C46.5127 14.3658 46.2516 14.6269 46.2516 14.9497V32.6339C46.2516 32.9632 46.5193 33.2309 46.8486 33.2309H56.7778ZM68.3643 33.2309H70.6724C71.0031 33.2309 71.2708 32.9645 71.2695 32.6339V19.7076C71.2695 19.3783 71.0018 19.1106 70.6724 19.1106H65.0813V19.1132C61.2353 19.1736 58.1359 22.3097 58.1359 26.1701C58.1359 30.0699 61.297 33.2309 65.1967 33.2309L68.2384 33.2178C68.279 33.2256 68.321 33.2309 68.3643 33.2309ZM74.2035 33.2309H76.5116C76.5562 33.2322 76.5982 33.227 76.6402 33.2178L79.754 33.2309C83.6538 33.2296 86.8148 30.0699 86.8148 26.1701C86.8148 22.3097 83.7141 19.1749 79.8681 19.1132V19.1106H77.1087V14.9628C77.1087 14.6335 76.841 14.3658 76.5116 14.3658H74.1904C73.8676 14.3658 73.6065 14.6269 73.6065 14.9497V32.6339C73.6065 32.9632 73.8741 33.2309 74.2035 33.2309Z" fill={`url(#${gradientId4})`}/>
      <defs>
        <linearGradient id={gradientId1} x1="42.6559" y1="48.0568" x2="89.7124" y2="1.00021" gradientUnits="userSpaceOnUse">
          <stop stopColor="#AE2C87"/>
          <stop offset="0.9997" stopColor="#ED7067"/>
          <stop offset="1" stopColor="#ED7067"/>
        </linearGradient>
        <linearGradient id={gradientId2} x1="42.6559" y1="48.0568" x2="89.7124" y2="1.00021" gradientUnits="userSpaceOnUse">
          <stop stopColor="#AE2C87"/>
          <stop offset="0.9997" stopColor="#ED7067"/>
          <stop offset="1" stopColor="#ED7067"/>
        </linearGradient>
        <linearGradient id={gradientId3} x1="42.6559" y1="48.0568" x2="89.7124" y2="1.00021" gradientUnits="userSpaceOnUse">
          <stop stopColor="#AE2C87"/>
          <stop offset="0.9997" stopColor="#ED7067"/>
          <stop offset="1" stopColor="#ED7067"/>
        </linearGradient>
        <linearGradient id={gradientId4} x1="42.6559" y1="48.0568" x2="89.7124" y2="1.00021" gradientUnits="userSpaceOnUse">
          <stop stopColor="#AE2C87"/>
          <stop offset="0.9997" stopColor="#ED7067"/>
          <stop offset="1" stopColor="#ED7067"/>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default LogoTopLab;



