import { createContext } from "react";
import { IPagesContext } from "../../interfaces/IPagesContext";

export default createContext({
  inHome: false,
  inQuemSomos: false,
  inNossasUnidades: false,
  inPoliticaPrivacidade: false,
  toHome: () => {},
  toQuemSomos: () => {console.log('sdf')},
  toNossasUnidades: () => {},
  toPolicaPrivacidade: () => {},
} as IPagesContext);