import LayoutContext from "components/Layout/LayoutContext";
import { mobileTopBarHeight, topBarHeight } from "components/TopBar/style";
import { HomeRoutePath, NossasUnidadesHashName, NossasUnidadesRoutePath, QuemSomosHashName, QuemSomosRoutePath, TermsRoutePath } from "library/Routes";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { matchPath, useHistory, useLocation } from "react-router";
import { animateScroll, scroller } from 'react-scroll';
import { IPagesContext } from "../../interfaces/IPagesContext";
import PagesContext from './PagesContext';

interface Props {
  children: any;
}

const shortDuration: number = 500;

const Pages = ({
  children
}: Props) => {
  const history = useHistory();
  const location = useLocation();
  const [currPath, setCurrPath] = useState<string>(window.location.pathname);
  const [currHash, setCurrHash] = useState<string>(window.location.hash.split('#').join(''));
  const {isSmSize} = useContext(LayoutContext);
  const [initialized, setInitialized] = useState<boolean>();

  const scrollToOptions = useMemo<any>(() => {
    return {
      duration: shortDuration,
      delay: 200,
      offset: parseInt(isSmSize ? mobileTopBarHeight : topBarHeight) * -1,
      smooth: true,
      ignoreCancelEvents: false
    }
  }, [isSmSize])
 
  const inHome = useMemo<boolean>(() => {
    return !!(currPath && matchPath(currPath, {
      path: HomeRoutePath,
      exact: true
    }));
  }, [currPath])
 
  const inQuemSomos = useMemo<boolean>(() => {
    return inHome && currHash === QuemSomosHashName;
  }, [inHome, currHash])
 
  const inNossasUnidades = useMemo<boolean>(() => {
    return inHome && currHash === NossasUnidadesHashName;
  }, [inHome, currHash])
 
  const inPoliticaPrivacidade = useMemo<boolean>(() => {
    return !!(currPath && matchPath(currPath, {
      path: TermsRoutePath,
      exact: true
    }));
  }, [currPath])

  const toHome = useCallback(() => {
    new Promise((resolve) => {
      history.push(HomeRoutePath);
      resolve(undefined);
    }).then(() => animateScroll.scrollToTop({ duration: inHome ? shortDuration : 0 }));
  }, [history, inHome])

  const toQuemSomos = useCallback(() => {
    if (inQuemSomos) {
      scroller.scrollTo(QuemSomosHashName, scrollToOptions)
    } else {
      new Promise((resolve) => {
        history.push(QuemSomosRoutePath);
        resolve(undefined);
      }).then(() => scroller.scrollTo(QuemSomosHashName, scrollToOptions));
    }
  }, [inQuemSomos, history, scrollToOptions])

  const toNossasUnidades = useCallback(() => {
    if (inNossasUnidades) {
      scroller.scrollTo(NossasUnidadesHashName, scrollToOptions)
    } else {
      new Promise((resolve) => {
        history.push(NossasUnidadesRoutePath);
        resolve(undefined);
      }).then(() => scroller.scrollTo(NossasUnidadesHashName, scrollToOptions));
    }
  }, [inNossasUnidades, history, scrollToOptions])

  const toPolicaPrivacidade = useCallback(() => {
    new Promise((resolve) => {
      history.push(TermsRoutePath);
      resolve(undefined);
    }).then(() => animateScroll.scrollToTop({ duration: inPoliticaPrivacidade ? shortDuration : 0 }));
  }, [history, inPoliticaPrivacidade])

  useEffect(() => {
    setCurrPath(location.pathname);
    setCurrHash(location.hash.split('#').join(''));
  }, [location]);

  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      if (inQuemSomos) {
        window.setTimeout(toQuemSomos, 500);
      }
      if (inNossasUnidades) {
        window.setTimeout(toNossasUnidades, 500);
      }
    }
  }, [initialized, setInitialized, inQuemSomos, toQuemSomos, inNossasUnidades, toNossasUnidades])
  
  const contextValue = useMemo<IPagesContext>(() => {
    return {
      inHome,
      inQuemSomos,
      inNossasUnidades,
      inPoliticaPrivacidade,
      toHome,
      toQuemSomos,
      toNossasUnidades,
      toPolicaPrivacidade,
    }
  }, [inHome, inQuemSomos, inNossasUnidades, inPoliticaPrivacidade, toHome, toQuemSomos, toNossasUnidades, toPolicaPrivacidade])

  return (
    <PagesContext.Provider value={contextValue}>
      {children}
    </PagesContext.Provider>
  )
}

export default Pages;