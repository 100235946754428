import { Container, createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import { primaryColor, secondaryColor } from "library/Colors";
import { basePath, HomeHashName } from "library/Routes";
import { useTranslation } from "react-i18next";
import {Element} from 'react-scroll';
import { getNoCache } from "utils/NoCacheUtils";

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        position: 'relative',
        display: "flex",
        overflow: 'hidden',
        width: '100%',
      },
      container: {
        position: 'relative',
        width: '100%',
        height: '100%',
        minHeight: '312px',
        maxHeight: '312px',
        '@media (max-width: 959px)': {
          textAlign: 'center',
          minHeight: 'initial',
          maxHeight: 'initial',
        },
      },
      label: {
        position: 'absolute',
        top: '50%',
        right: '865px',
        transform: 'translateY(-50%)',
        fontSize: '32px',
        fontStyle: 'italic',
        fontWeight: 'bold',
        color: primaryColor,
        zIndex: 1,
        '& .novo-conceito': {
          color: secondaryColor
        },
        [theme.breakpoints.down('md')]: {
          right: `${865 * 100 / theme.breakpoints.values.lg}vw`
        },
        [theme.breakpoints.down('sm')]: {
          position: 'relative',
          top: 'inherit',
          right: 'inherit',
          transform: 'inherit',
          margin: theme.spacing(4, 0),
        }
      },
      bgContent: {
        position: 'absolute',
        top: 0,
        right: 0,
        minHeight: `314px`,
        maxHeight: `314px`,
        '@media (max-width: 959px)': {
          position: 'relative',
          top: 'inherit',
          bottom: 'inherit',
          right: 'inherit',
          minHeight: `initial`,
          maxHeight: `initial`,
        },
      },
      bg: {
        position: 'absolute',
        top: 0,
        right: '-200px',
        '@media (max-width: 959px)': {
          display: 'none',
        },
      },
      bgTop: {
        right: '780px',
        position: 'absolute',
        '@media (max-width: 959px)': {
          display: 'none',
        },
      },
      mobiletop: {
        display: 'none',
        width: '90%',
        '@media (max-width: 959px)': {
          display: 'block',
          top: '0',
          right: '-30px',
          margin:'22px',
        },
      },
      mobileBg: {
        display: 'none',
        '@media (max-width: 959px)': {
          display: 'block',
          top: '0',
          right: '-30px',
        },
        '@media (max-width: 720px)': {
          width: `${959 * 100 / 720}vw`
        }
      },
    }),
);

const NovoConceito = () => {
  const {t} = useTranslation();
  const classes = useStyles();

  return (
    <Element
      name={HomeHashName}
      className={classes.root}
    >
      <Container className={classes.container}>
      <div className={classes.bgContent}>
          <img
            alt=""
            src={`${basePath}assets/images/bg-top.png?nocache=${getNoCache()}`}
            className={classes.bgTop}
          />
          <img
            alt=""
            src={`${basePath}assets/images/bg-top.png?nocache=${getNoCache()}`}
            className={classes.mobiletop}
          />
        </div>
        
        <div className={classes.bgContent}>
          <img
            alt=""
            src={`${basePath}assets/images/bg-conceitoAtual.png?nocache=${getNoCache()}`}
            className={classes.bg}
          />
          <img
            alt=""
            src={`${basePath}assets/images/bg-conceitoAtual.png?nocache=${getNoCache()}`}
            className={classes.mobileBg}
          />
        </div>
      </Container>
    </Element>
  )
}

export default NovoConceito;