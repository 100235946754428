import { Container, createStyles, IconButton, Link as MaterialLink, makeStyles, Theme, Typography } from '@material-ui/core';
import WhatsAppIcon from 'components/Icons/WhatsAppIcon';
import LogoTopLab from 'components/LogoTopLab/LogoTopLab';
import PagesContext from 'components/Pages/PagesContext';
import { topBarHeight } from 'components/TopBar/style';
import { coralColor, footerColor, primaryColor, secondaryColor, whatsAppColor, whatsAppDarkColor, whiteColor } from 'library/Colors';
import { basePath, HomeRoutePath, OrientacoesExamesUrl, QuemSomosHashName, ResultadosExamesUrl, TermsRoutePath } from 'library/Routes';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import { Element, scroller } from 'react-scroll';

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        position: 'relative',
        display: "flex",
        flexDirection: 'column',
        width: '100%',
      },
      container1: {
        display: 'flex',
        width: '100%',
        backgroundColor: footerColor,
        color: whiteColor,
        paddingTop: '36px',
        paddingBottom: '36px',
      },
      container1Content: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        [theme.breakpoints.down('md')]: {
          flexDirection: 'column',
        }
      },
      container2: {
        display: 'flex',
        width: '100%',
      },
      logo: {
        fontSize: '71px',
      },
      logoFooter: {
        marginTop: '35px',
        [theme.breakpoints.down('md')]: {
          maxWidth: '450px',
          textAlign: 'center',
        }
      },
      columnContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        padding: theme.spacing(0, 2),
        [theme.breakpoints.down('md')]: {
          // justifyContent: 'stretch',
          alignItems: 'center',
        }
      },
      logoContent: {
        maxWidth: '280px',
        [theme.breakpoints.down('md')]: {
          maxWidth: 'initial'
        }
      },
      menuContent: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '200px',
        [theme.breakpoints.down('md')]: {
          maxWidth: 'initial',
          marginTop: theme.spacing(2),
        }
      },
      menuItem: {
        position: 'relative',
        fontSize: '14px',
        fontWeight: 400,
        color: whiteColor,
        lineHeight: '17px',
        textDecoration: 'none !important',
        marginTop: theme.spacing(3),
        '&:first-child': {
          marginTop: 0,
        },
        [theme.breakpoints.down('md')]: {
          margin: 0,
          padding: theme.spacing(1, 0),
          width: '100%',
          maxWidth: '240px',
          textAlign: 'center',
          lineHeight: '24px',
          '&:before': {
            content: '""',
            position: 'absolute',
            width: '100%',
            height: '1px',
            left: 0,
            top: 0,
            background: `linear-gradient(226.03deg, ${coralColor} 4.7%, ${secondaryColor} 96.58%);`
          },
          '&:first-child:before': {
            content: 'none'
          }
        }
      },
      selectedMenuItem: {
        fontWeight: 600
      },
      addressContent: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('md')]: {
          marginTop: theme.spacing(3)
        }
      },
      columnItem: {
        marginTop: theme.spacing(2),
        '&:first-child': {
          marginTop: 0,
        },
        [theme.breakpoints.down('md')]: {
          maxWidth: '450px',
          marginTop: theme.spacing(4)
        }
      },
      opacityItem: {
        opacity: 0.5
      },
      othersContent: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('md')]: {
          marginTop: theme.spacing(4)
        },
      },
      columnTitle: {
        fontSize: '14px',
        fontWeight: 500,
        textAlign: 'left',
        [theme.breakpoints.down('md')]: {
          textAlign: 'center',
        },
      },
      columnText: {
        marginTop: theme.spacing(1),
        fontSize: '12px',
        fontWeight: 400,
        textAlign: 'left',
        [theme.breakpoints.down('md')]: {
          textAlign: 'center',
        },
      },
      socialIconsContent: {
        paddingTop: theme.spacing(0.7),
      },
      socialIconButton: {
        minWidth: 'initial',
        padding: theme.spacing(0.5),
        [theme.breakpoints.up('md')]: {
          '&:first-child': {
            marginLeft: theme.spacing(-0.5),
          },
        },
        [theme.breakpoints.down('md')]: {
          margin: theme.spacing(0, 1)
        },
      },
      socialIcon: {
        fontSize: '16px',
      },
      subFooterWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: theme.spacing(3, 0),
        [theme.breakpoints.down('md')]: {
          flexDirection: 'column',
          alignItems: 'center',
        }
      },
      subFooterItem: {
        display: 'flex',
        height: `${theme.spacing(4)}px`,
        flexDirection: 'row',
        alignItems: 'center',
      },
      subFooterText: {
        fontSize: '16px',
        fontWeight: 500,
        color: primaryColor,
        lineHeight: `${theme.spacing(4)}px`,
        [theme.breakpoints.down('md')]: {
          textAlign: 'center',
        }
      },
      copyright: {
        [theme.breakpoints.down('md')]: {
          lineHeight: '20px',
          marginTop: theme.spacing(2),
          order: 2
        }
      },
      developedBy: {
        marginLeft: theme.spacing(5),
        [theme.breakpoints.down('md')]: {
          marginTop: theme.spacing(2),
          marginLeft: 'initial',
          order: 3
        }
      },
      developedByLabel: {
        marginRight: theme.spacing(1),
      },
      privacyPolicy: {
        [theme.breakpoints.down('md')]: {
          order: 1
        }
      },
      whatsAppIconWrapper: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        maxWidth: theme.breakpoints.values.lg,
      },
      whatsAppContent: {
        position: 'absolute',
        right: '50px',
        bottom: '50px',
      },
      whatsAppIcon: {
        position: 'absolute',
        right: '0',
        bottom: '0',
        backgroundColor: `${whatsAppColor} !important`,
        width: '50px',
        height: '50px',
        fontSize: '21px',
        boxShadow: `0px 4px 4px rgba(0, 0, 0, 0.25)`,
        boxSizing: 'border-box',
      },
      whatsAppTag: {
        position: 'absolute',
        width: '150px',
        height: '40px',
        bottom: '5px',
        right: '10px',
        borderRadius: '40px',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '12px',
        lineHeight: '14px',
        textAlign: 'center',
        color: whiteColor,
        backgroundColor: whatsAppDarkColor,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        paddingLeft: theme.spacing(2),
        paddingTop: theme.spacing(.5),
        boxSizing: 'border-box',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        transition: theme.transitions.create('width', {
          duration: theme.transitions.duration.short,
          easing: theme.transitions.easing.easeInOut,
        })
      },
      hiddenWhatsAppTag: {
        width: 0
      },
    }),
);

interface Props {
  className?: string;
}

const Footer = ({
  className,
}: Props) => {
  const {t} = useTranslation();
  const classes = useStyles();
  const [scrollY, setScrollY] = useState<number>(0);
  const [footerTop, setFooterTop] = useState<number>();
  const {toHome, toQuemSomos, toPolicaPrivacidade} = useContext(PagesContext);
  
  const expandedWhatsAppIcon = useMemo<boolean>(() => {
    return typeof scrollY === 'number' && typeof footerTop === 'number' && scrollY < (footerTop - window.innerHeight);
  }, [scrollY, footerTop]);

  const onScroll = () => {
    setFooterTop(scroller.get('footer').offsetTop + parseInt(topBarHeight));
    setScrollY(window.scrollY);
  }

  useEffect(() => {
    setFooterTop(scroller.get('footer').offsetTop + parseInt(topBarHeight));
    setScrollY(window.scrollY);
    window.document.addEventListener('scroll', onScroll);
    return () => {
      window.document.removeEventListener('scroll', onScroll);
    }
  }, [])

  useEffect(() => {

  },)

  return (
    <Element 
      name="footer"
      className={[
        classes.root,
        className || ''
      ].join(' ')}
    >
      <div className={classes.container1}>
        <Container className={classes.container1Content}>
          <div className={[
            classes.columnContent,
            classes.logoContent
          ].join(' ')}>
            <LogoTopLab inverted className={classes.logo} />
            {/* <Typography component="p" className={classes.logoFooter}>
              texto sobre a empresa pensando nas palavras chaves para melhorar o seo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Et et consectetur vitae, neque congue nulla eu a. Pellentesque risus nulla potenti lectus.
            </Typography> */}
          </div>
          <div className={[
            classes.columnContent,
            classes.menuContent
          ].join(' ')}>
            <Link
              to={HomeRoutePath}
              onClick={e => {
                e.preventDefault();
                toHome();
              }}
              className={classes.menuItem}
            >
              {t(`menu.home`)}
            </Link>
            <Link
              to={`${HomeRoutePath}#${QuemSomosHashName}`}
              onClick={e => {
                e.preventDefault();
                toQuemSomos();
              }}
              className={classes.menuItem}
            >
              {t(`menu.quemSomos`)}
            </Link>
            <MaterialLink href={OrientacoesExamesUrl} target="_blank" className={classes.menuItem}>
              {t(`menu.orientacoesExames`)}
            </MaterialLink>
            <MaterialLink href={ResultadosExamesUrl} target="_blank" className={classes.menuItem}>
              {t(`menu.resultadoExames`)}
            </MaterialLink>
            <Link
              to={TermsRoutePath}
              onClick={e => {
                e.preventDefault();
                toPolicaPrivacidade();
              }}
              className={classes.menuItem}
            >
              {t(`menu.politicaPrivacidade`)}
            </Link>
          </div>
          <div className={[
            classes.columnContent,
            classes.addressContent
          ].join(' ')}>
            <div className={classes.columnItem}>
              <Typography component="p" className={classes.columnTitle}>
                {t(`Footer.locations.portoAlegre.city`)}
              </Typography>
              <Typography component="p" className={classes.columnText}>
                {t(`Footer.locations.portoAlegre.address`)}
              </Typography>
              <Typography component="p" className={classes.columnText}>
                <span dangerouslySetInnerHTML={{ __html: t(`Footer.locations.portoAlegre.when`) }} />
              </Typography>
            </div>
            <div className={classes.columnItem}>
              <Typography component="p" className={classes.columnTitle}>
                {t(`Footer.locations.mostardeiro.city`)}
              </Typography>
              <Typography component="p" className={classes.columnText}>
                {t(`Footer.locations.mostardeiro.address`)}
              </Typography>
              <Typography component="p" className={classes.columnText}>
                <span dangerouslySetInnerHTML={{ __html: t(`Footer.locations.mostardeiro.when`) }} />
              </Typography>
            </div>
            <div className={classes.columnItem}>
              <Typography component="p" className={classes.columnTitle}>
                {t(`Footer.locations.xangrila.city`)}
              </Typography>
              <Typography component="p" className={classes.columnText}>
                {t(`Footer.locations.xangrila.address`)}
              </Typography>
              <Typography component="p" className={classes.columnText}>
                <span dangerouslySetInnerHTML={{ __html: t(`Footer.locations.xangrila.when`) }} />
              </Typography>
            </div>
          </div>
          <div className={[
            classes.columnContent,
            classes.othersContent
          ].join(' ')}>
            <div className={classes.columnItem}>
              <Typography component="p" className={classes.columnTitle}>
                {t(`Footer.centralAtendimento.title`)}
              </Typography>
              <Typography component="p" className={classes.columnText}>
                {t(`Footer.centralAtendimento.phone`)}
              </Typography>
            </div>
             {/* <div className={classes.columnItem}>
              <Typography component="p" className={classes.columnTitle}>
                {t(`Footer.whatsApp.title`)}
              </Typography>
              <Typography component="p" className={classes.columnText}>
                {t(``)}
              </Typography>
            </div>
            {/* <div className={classes.columnItem}>
              <Typography component="p" className={classes.columnTitle}>
                {t(`Footer.redesSociais.title`)}
              </Typography>
              <div className={classes.socialIconsContent}>
                <Button className={classes.socialIconButton}>
                  <FacebookIcon className={classes.socialIcon} />
                </Button>
                <Button className={classes.socialIconButton}>
                  <WhatsAppIcon className={classes.socialIcon} />
                </Button>
                <Button className={classes.socialIconButton}>
                  <InstagramIcon className={classes.socialIcon} />
                </Button>
                <Button className={classes.socialIconButton}>
                  <LinkedInIcon className={classes.socialIcon} />
                </Button>
              </div>
            </div> */}
          </div>
        </Container>
      </div>
      <div className={classes.container2}>
        <Container>
          <div
            className={classes.subFooterWrapper}
          >
            <div
              className={[
                classes.subFooterItem,
                classes.subFooterText,
                classes.copyright,
              ].join(' ')}
            >
              {t(`Footer.copyright`)}
            </div>
            <div className={[
              classes.subFooterItem,
              classes.developedBy,
            ].join(' ')}>
              <Typography
                component="span"
                className={[
                  classes.subFooterText,
                  classes.developedByLabel,
                ].join(' ')}
              >
                {t(`Footer.developedByLabel`)}
              </Typography>
              <a href="https://www.dbccompany.com.br/" target="_blank" rel="noreferrer">
                <img src={`${basePath}assets/images/dbc.png`} alt={t(`Footer.developedByImageAlt`)} />
              </a>
            </div>
            <div style={{flex: 1}}></div>
          </div>
        </Container>
      </div>
      {/* <div className={classes.whatsAppIconWrapper}>
        <div className={classes.whatsAppContent}>
          <div
            className={[
              classes.whatsAppTag,
              !expandedWhatsAppIcon ? classes.hiddenWhatsAppTag: ''
            ].join(' ')}
          >
            Fale conosco<br />
            pelo whatsapp!
          </div>
          <MaterialLink
            className={classes.whatsAppIcon}
            component={IconButton}
            href="https://api.whatsapp.com/send?phone=555133164856"
            target="_blank"
          >
            <WhatsAppIcon color={whiteColor} />
          </MaterialLink>
        </div>
      </div> */}
    </Element>
  )
}

export default Footer;