import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {secondaryColor, coralColor, whiteColor} from 'library/Colors';

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '8px',
        background: `linear-gradient(45deg, ${secondaryColor} 0%, ${coralColor} 100%)`,
        maxHeight: '39px',
        paddingLeft: '6px',
        paddingRight: '6px',
      },
      label: {
        color: whiteColor,
        fontSize: '12px',
        fontWeight: 600,
        whiteSpace: 'nowrap',
      },
      phone: {
        color: whiteColor,
        fontSize: '19px',
        fontWeight: 'bold',
        marginTop: '-5px',
        whiteSpace: 'nowrap',
      },
    }),
);

interface Props {
  className?: string;
}

const SeloCentralAtendimento = ({
  className,
}: Props) => {
  const {t} = useTranslation();
  const classes = useStyles();

  return (
    <div className={[
      classes.root,
      className || ''
    ].join(' ')}>
      <Typography className={classes.label} component="p">{t(`SeloCentralAtendimento.label`)}</Typography>
      <Typography className={classes.phone} component="p">{t(`SeloCentralAtendimento.phone`)}</Typography>
    </div>
  )
}

export default SeloCentralAtendimento;