import { CircularProgress, MuiThemeProvider } from '@material-ui/core';
import Layout from 'components/Layout/Layout';
import { getAllRouteComponents } from 'library/Routes';
import React, { Suspense, useMemo, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import {LayoutMuiTheme} from 'components/Layout/LayoutMuiTheme';
import {IAppContext} from 'interfaces/IAppContext';
import {Events} from 'react-scroll';
import { useEffect } from 'react';
import AppContext from './AppContext';

function App() {

  const [scrollAnimating, setScrollAnimating] = useState<boolean>(false);

  const contextValue = useMemo<IAppContext>(() => {
    return {
      scrollAnimating
    }
  }, [scrollAnimating])

  useEffect(() => {
    Events.scrollEvent.register('begin', () => setScrollAnimating(true));
    Events.scrollEvent.register('end', () => setScrollAnimating(false));
  }, [setScrollAnimating]);

  return (
    <AppContext.Provider value={contextValue}>
      <MuiThemeProvider theme={LayoutMuiTheme}>
        <Suspense fallback={<CircularProgress />}>
          <Router>
            <Layout>
              {getAllRouteComponents()}
            </Layout>
          </Router>
        </Suspense>
      </MuiThemeProvider>
    </AppContext.Provider>
  );
}

export default App;
