import { useMediaQuery, useTheme } from "@material-ui/core";
import TopBar from 'components/TopBar/TopBar';
import Pages from 'components/Pages/Pages';
import { ILayoutContext } from "interfaces/ILayoutContext";
import { useMemo, useState } from "react";
import { Switch } from "react-router";
import LayoutContext from './LayoutContext';
import Footer from 'components/Footer/Footer';
import LeftMenu from 'components/LeftMenu/LeftMenu';

interface Props {
  children?: any
}

const Layout = ({
  children,
}: Props) => {
  const theme = useTheme();
  const isXsSize = useMediaQuery(theme.breakpoints.up('xs'));
  const isSmSize = useMediaQuery(theme.breakpoints.up('sm'));
  const isMdSize = useMediaQuery(theme.breakpoints.up('md'));
  const isLgSize = useMediaQuery(theme.breakpoints.up('lg'));
  const isXlSize = useMediaQuery(theme.breakpoints.up('xl'));
  const [leftMenuOpened, setLeftMenuOpened] = useState<boolean>(false);

  const contextValue = useMemo<ILayoutContext>(() => {
    return {
      isXsSize,
      isSmSize,
      isMdSize,
      isLgSize,
      isXlSize,
      leftMenuOpened,
      setLeftMenuOpened,
    }
  }, [isXsSize, isSmSize, isMdSize, isLgSize, isXlSize, leftMenuOpened, setLeftMenuOpened])

  return (
    <LayoutContext.Provider value={contextValue}>
      <Pages>
        <TopBar />
        <Switch>
          {children}
        </Switch>
        <Footer />
        <LeftMenu />
      </Pages>
    </LayoutContext.Provider>
  )
}

export default Layout;