import { Button, AppBar, Container, IconButton, Toolbar } from "@material-ui/core";
import MenuIcon from "components/Icons/MenuIcon";
import LayoutContext from "components/Layout/LayoutContext";
import PagesContext from "components/Pages/PagesContext";
import { HomeRoutePath, NossasUnidadesHashName, OrientacoesExamesUrl, QuemSomosHashName, ResultadosExamesUrl } from "library/Routes";
import { Fragment, useContext } from "react";
import { useTranslation } from 'react-i18next';
import LogoTopLab from "../LogoTopLab/LogoTopLab";
import MenuItem from './MenuItem';
import SeloCentralAtendimento from './SeloCentralAtendimento';
import useStyles from './style';

const TopBar = () => {
  const classes = useStyles();
  const {t} = useTranslation();
  const {isMdSize, setLeftMenuOpened} = useContext(LayoutContext);
  const {toHome, toQuemSomos, toNossasUnidades} = useContext(PagesContext);

  return (
    <Fragment>
      <AppBar color="transparent" position="fixed" className={classes.appBar}>
        <Toolbar className={classes.topBar}>
          <Container className={classes.topBarContainer}>
            {
              !isMdSize && (<div></div>)
            }
            <Button
              className={classes.brandButton}
              onClick={() => toHome()}
            >
              <LogoTopLab className={classes.brand} />
            </Button>
            {
              isMdSize ? (
                <Fragment>
                  <div style={{flex: 1}}></div>
                  <MenuItem label={t(`menu.home`)} path={HomeRoutePath} onClick={() => toHome()} />
                  <MenuItem label={t(`menu.quemSomos`)} path={`${HomeRoutePath}#${QuemSomosHashName}`} onClick={toQuemSomos} />
                  <MenuItem label={t(`menu.orientacoesExames`)} link={OrientacoesExamesUrl} />
                  <MenuItem label={t(`menu.resultadoExames`)} link={ResultadosExamesUrl} />
                  <MenuItem label={t(`menu.nossasUnidades`)} path={`${HomeRoutePath}#${NossasUnidadesHashName}`} onClick={toNossasUnidades} />
                  <SeloCentralAtendimento className={classes.seloCentralAtendimento} />
                </Fragment>
              ) : (
                <div
                  className={classes.menuButtonContainer}
                >
                  <IconButton
                    className={classes.menuButton}
                    onClick={() => setLeftMenuOpened(true)}
                  >
                    <MenuIcon
                      className={classes.menuIcon}
                      color="primary"
                    />
                  </IconButton>
                </div>
              )
            }

          </Container>
        </Toolbar>
      </AppBar>
      <div className={classes.appBarRelative}>
      </div>
    </Fragment>
  )
}

export default TopBar;