export const primaryColor = '#22096F';

export const secondaryColor = '#B41E8E';

export const footerColor = '#130443';

export const coralColor = '#FC6D6A';

export const whiteColor = '#FFF';

export const whatsAppColor = '#25D366';
export const whatsAppDarkColor = '#009D3B';