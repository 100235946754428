import {IRoute} from 'interfaces/IRoute';
import Home from 'pages/Home';
import Terms from 'pages/Terms';
import { Route } from 'react-router-dom';

let routes: IRoute[] = [];

export const basePath: string = '/';

export const HomeRoutePath: string = basePath;
export const HomeRoute: IRoute = {
  path: HomeRoutePath,
  component: Home,
  exact: true,
};
routes.push(HomeRoute);

export const HomeHashName: string = 'home';
export const QuemSomosHashName: string = 'quem-somos';
export const QuemSomosRoutePath: string = `${HomeRoutePath}#${QuemSomosHashName}`;
export const NossasUnidadesHashName: string = 'nossas-unidades';
export const NossasUnidadesRoutePath: string = `${HomeRoutePath}#${NossasUnidadesHashName}`;
export const OrientacoesExamesUrl: string = 'https://zenite.unimedpoa.com.br/Reports/ExamesOferecidos/Default.aspx?dominio=TOPLAB';
export const ResultadosExamesUrl: string = 'https://zenite.unimedpoa.com.br/resultados/TOPLAB/';

export const TermsRoutePath: string = basePath + 'politica-privacidade';
export const TermsRoute: IRoute = {
  path: TermsRoutePath,
  component: Terms,
  exact: true,
};
routes.push(TermsRoute);

export function getRouteComponent(route: IRoute, key: number) {
  const args = {
    path: route.path || undefined,
    component: route.component,
    exact: route.exact
  };
  return <Route {...args} key={key} />;
}

export function getAllRouteComponents() {
  return routes.map((route, index) => getRouteComponent(route, index));
}