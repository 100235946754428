import { createMuiTheme, darken, lighten } from '@material-ui/core/styles';
import { primaryColor as primary, secondaryColor as secondary, whiteColor } from 'library/Colors';

const primaryColor = {
  50: lighten(primary, 1),
  100: lighten(primary, 0.8),
  200: lighten(primary, 0.6),
  300: lighten(primary, 0.4),
  400: lighten(primary, 0.2),
  500: primary,
  600: darken(primary, 0.2),
  700: darken(primary, 0.4),
  800: darken(primary, 0.6),
  900: darken(primary, 0.8),
  A100: lighten(primary, 0.8),
  A200: lighten(primary, 0.6),
  A400: lighten(primary, 0.2),
  A700: darken(primary, 0.4),
  'contrastDefaultColor': whiteColor
};
const secondaryColor = {
  50: lighten(secondary, 1),
  100: lighten(secondary, 0.8),
  200: lighten(secondary, 0.6),
  300: lighten(secondary, 0.4),
  400: lighten(secondary, 0.2),
  500: secondary,
  600: darken(secondary, 0.2),
  700: darken(secondary, 0.4),
  800: darken(secondary, 0.6),
  900: darken(secondary, 0.8),
  A100: lighten(secondary, 0.8),
  A200: lighten(secondary, 0.6),
  A400: lighten(secondary, 0.2),
  A700: darken(secondary, 0.4),
  'contrastDefaultColor': whiteColor,
};

export const LayoutMuiTheme = createMuiTheme({
  typography: {
    fontFamily: '"Aileron Regular", sans-serif',
  },
  palette: {
    primary: primaryColor,
    secondary: secondaryColor,
  },
  overrides: {
    MuiContainer: {
      root: {
        paddingLeft: '24px',
        paddingRight: '24px',
      }
    }
  }
});