import { Container, createStyles, Divider, makeStyles, Theme, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        position: 'relative',
        display: "flex",
        overflow: 'hidden',
        width: '100%',
      },
      container: {
        position: 'relative',
        width: '100%',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
      },
      title: {
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '28px',
        lineHeight: '34px',
      },
      content: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(7),
      },
      text: {
        paddingTop: theme.spacing(2),
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '19px',
        '& strong': {
          fontWeight: 600,
        }
      },
      subTitle: {
        paddingTop: theme.spacing(3),
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '21px',
        lineHeight: '25px',
      },
      divider: {
        marginTop: theme.spacing(3.5),
      }
    }),
);

const Terms = () => {
  const {t} = useTranslation();
  const classes = useStyles();

  return (
    <Container className={classes.container}>
      <Typography component="h2" className={classes.title} color="primary">
        {t(`Terms.title`)}
      </Typography>
      <div className={classes.content}>
        <Typography component="p" className={classes.text} color="primary">
          <span dangerouslySetInnerHTML={{ __html: t(`Terms.text0.text`) }} />
        </Typography>

        <Typography component="h4" className={classes.subTitle} color="primary">
          <span dangerouslySetInnerHTML={{ __html: t(`Terms.text1.title`) }} />
        </Typography>
        <Typography component="p" className={classes.text} color="primary">
          <span dangerouslySetInnerHTML={{ __html: t(`Terms.text1.text`) }} />
        </Typography>

        <Divider className={classes.divider} />

        <Typography component="h4" className={classes.subTitle} color="primary">
          <span dangerouslySetInnerHTML={{ __html: t(`Terms.text2.title`) }} />
        </Typography>
        <Typography component="p" className={classes.text} color="primary">
          <span dangerouslySetInnerHTML={{ __html: t(`Terms.text2.text`) }} />
        </Typography>

        <Divider className={classes.divider} />

        <Typography component="h4" className={classes.subTitle} color="primary">
          <span dangerouslySetInnerHTML={{ __html: t(`Terms.text3.title`) }} />
        </Typography>
        <Typography component="p" className={classes.text} color="primary">
          <span dangerouslySetInnerHTML={{ __html: t(`Terms.text3.text`) }} />
        </Typography>

        <Divider className={classes.divider} />

        <Typography component="h4" className={classes.subTitle} color="primary">
          <span dangerouslySetInnerHTML={{ __html: t(`Terms.text4.title`) }} />
        </Typography>
        <Typography component="p" className={classes.text} color="primary">
          <span dangerouslySetInnerHTML={{ __html: t(`Terms.text4.text`) }} />
        </Typography>

        <Divider className={classes.divider} />

        <Typography component="h4" className={classes.subTitle} color="primary">
          <span dangerouslySetInnerHTML={{ __html: t(`Terms.text5.title`) }} />
        </Typography>
        <Typography component="p" className={classes.text} color="primary">
          <span dangerouslySetInnerHTML={{ __html: t(`Terms.text5.text`) }} />
        </Typography>

        <Divider className={classes.divider} />

        <Typography component="h4" className={classes.subTitle} color="primary">
          <span dangerouslySetInnerHTML={{ __html: t(`Terms.text6.title`) }} />
        </Typography>
        <Typography component="p" className={classes.text} color="primary">
          <span dangerouslySetInnerHTML={{ __html: t(`Terms.text6.text`) }} />
        </Typography>

        <Divider className={classes.divider} />

        <Typography component="h4" className={classes.subTitle} color="primary">
          <span dangerouslySetInnerHTML={{ __html: t(`Terms.text7.title`) }} />
        </Typography>
        <Typography component="p" className={classes.text} color="primary">
          <span dangerouslySetInnerHTML={{ __html: t(`Terms.text7.text`) }} />
        </Typography>

        <Divider className={classes.divider} />

        <Typography component="h4" className={classes.subTitle} color="primary">
          <span dangerouslySetInnerHTML={{ __html: t(`Terms.text8.title`) }} />
        </Typography>
        <Typography component="p" className={classes.text} color="primary">
          <span dangerouslySetInnerHTML={{ __html: t(`Terms.text8.text`) }} />
        </Typography>

        <Divider className={classes.divider} />

        <Typography component="h4" className={classes.subTitle} color="primary">
          <span dangerouslySetInnerHTML={{ __html: t(`Terms.text9.title`) }} />
        </Typography>
        <Typography component="p" className={classes.text} color="primary">
          <span dangerouslySetInnerHTML={{ __html: t(`Terms.text9.text`) }} />
        </Typography>

      </div>
    </Container>
  )
}

export default Terms;