import { Container, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { basePath, QuemSomosHashName } from 'library/Routes';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Element } from 'react-scroll';
import { getNoCache } from 'utils/NoCacheUtils';

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        position: 'relative',
        display: "flex",
        width: '100%',
      },
      container: {
        position: 'relative',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        '@media (max-width: 959px)': {
          flexDirection: 'column',
          paddingLeft: 0,
          paddingRight: 0,
          paddingBottom: theme.spacing(3),
        },
      },
      textContent: {
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: theme.spacing(3),
        '@media (max-width: 959px)': {
          paddingLeft: 0,
          order: 1,
          paddingBottom: theme.spacing(3),
        },
      },
      title: {
        fontFamily: '"HK Nova Medium", sans-serif',
        fontSize: '28px',
        fontWeight: 600,
        textAlign: 'left',
        '@media (max-width: 959px)': {
          padding: theme.spacing(0, 3)
        },
      },
      text: {
        fontSize: '16px',
        fontWeight: 500,
        '& strong': {
          fontWeight: 600,
        },
        textAlign: 'left',
        paddingTop: theme.spacing(2),
        '@media (max-width: 959px)': {
          order: 2,
          padding: theme.spacing(2, 3, 0, 3)
        },
      },
      bgContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        '@media (max-width: 1100px)': {
          overflowX: 'hidden',
          minWidth: `${529 * 100 / 1100}vw`,
        },
        '@media (max-width: 959px)': {
          order: 2,
        },
      },
      bg: {
        width: '529px',
        height: '345px',
        '@media (max-width: 959px)': {
          display: 'none',
        },
      },
      bgTop: {
        
        width: '26%',
        '@media (max-width: 959px)': {
          display: 'none',
        },
        
      },
      mobiletop: {
        width: '47vw',
        marginLeft: '0.8rem',
        '@media (min-width: 960px)': {
          display: 'none',
          paddingTop: '2em',
        },
      },
      mobileBg: {
        width: '100vw',
        '@media (min-width: 960px)': {
          display: 'none',
        },
      }
    }),
);

interface Props {
  className?: string;
}

const Conheca = ({
  className,
}: Props) => {
  const {t} = useTranslation();
  const classes = useStyles();

  return (
    <Element
      name={QuemSomosHashName}
      className={[
        classes.root,
        className || ''
      ].join(' ')}
    >
      <Container className={classes.container}>
        <div className={classes.bgContent}>
          <img
            alt=""
            className={classes.bg}
            src={`${basePath}assets/images/conheca-bg.png?nocache=${getNoCache()}`}
          />
          <img
            alt=""
            className={classes.mobileBg}
            src={`${basePath}assets/images/conheca-bg.png?nocache=${getNoCache()}`}
          />
        </div>
        <div className={classes.textContent}>
        <div className={classes.bgContent}>
          <img
            alt=""
            className={classes.bgTop}
            src={`${basePath}assets/images/bg-OToplab.png?nocache=${getNoCache()}`}
          />
           <img
            alt=""
            className={classes.mobiletop}
            src={`${basePath}assets/images/bg-OToplab.png?nocache=${getNoCache()}`}
          />
        
        </div>
          <Typography component="p" className={classes.text} color="primary">
            <span dangerouslySetInnerHTML={{ __html: t(`Home.Conheca.text`) }} />
          </Typography>
        </div>
      </Container>
    </Element>
  );
}

export default Conheca;