import React from 'react';
import { IconUIComponentProps, useIconStyle } from './Icon';

const LocationIcon = ({ color, size, style, className, noFill }: IconUIComponentProps) => {
  const styles = useIconStyle({ noFill, size, color });
  return (
    <svg
      className={[styles.icon, className].join(' ').trim()}
      fontSize={size}
      style={style}
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0)">
        <path d="M50 50C44.5 50 40 45.5 40 40C40 34.5 44.5 30 50 30C55.5 30 60 34.5 60 40C60 45.5 55.5 50 50 50ZM80 41C80 22.85 66.75 10 50 10C33.25 10 20 22.85 20 41C20 52.7 29.75 68.2 50 86.7C70.25 68.2 80 52.7 80 41ZM50 0C71 0 90 16.1 90 41C90 57.6 76.65 77.25 50 100C23.35 77.25 10 57.6 10 41C10 16.1 29 0 50 0Z" fill="url(#paint0_linear)"/>
      </g>
      <defs>
        <linearGradient id="paint0_linear" x1="86.3498" y1="4.83311" x2="-4.16291" y2="74.6938" gradientUnits="userSpaceOnUse">
          <stop stop-color="#FC6D6A"/>
          <stop offset="1" stop-color="#B41E8E"/>
        </linearGradient>
        <clipPath id="clip0">
          <rect width="100" height="100" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
}

export default LocationIcon;