import React from 'react';
import { IconUIComponentProps, useIconStyle } from './Icon';

const MenuIcon = ({ color, size, style, className, noFill }: IconUIComponentProps) => {
  const styles = useIconStyle({ noFill, size, color });
  return (
    <svg
      className={[styles.icon, className].join(' ').trim()}
      fontSize={size}
      style={style}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M5 9C5 8.44772 5.44772 8 6 8H24.4432C24.9955 8 25.4432 8.44772 25.4432 9C25.4432 9.55228 24.9955 10 24.4432 10H6C5.44772 10 5 9.55228 5 9Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M3 15.9983C3 15.446 3.44772 14.9983 4 14.9983H27.6644C28.2166 14.9983 28.6644 15.446 28.6644 15.9983C28.6644 16.5506 28.2166 16.9983 27.6644 16.9983H4C3.44772 16.9983 3 16.5506 3 15.9983Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M5 22.9966C5 22.4443 5.44772 21.9966 6 21.9966H24.4432C24.9955 21.9966 25.4432 22.4443 25.4432 22.9966C25.4432 23.5489 24.9955 23.9966 24.4432 23.9966H6C5.44772 23.9966 5 23.5489 5 22.9966Z" />
    </svg>
  );
}

export default MenuIcon;