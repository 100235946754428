import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { Fragment } from "react";
import Conheca from './Conheca';
import NossosServicos from './NossosServicos';
import NovoConceito from './NovoConceito';
import UnidadesAtendimento from './UnidadesAtendimento';

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      conheca: {
        paddingTop: theme.spacing(7)
      },
      servicos: {
        paddingTop: theme.spacing(7)
      },
    }),
);

const Home = () => {
  const classes = useStyles();

  return (
    <Fragment>
      <NovoConceito />
      <Conheca className={classes.conheca} />
      <NossosServicos className={classes.servicos} />
      <UnidadesAtendimento />
    </Fragment>
  )
}

export default Home;