import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { primaryColor, whiteColor } from "library/Colors";

export const topBarHeight: string = '70px';
export const mobileTopBarHeight: string = '52px';

export default makeStyles(
  (theme: Theme) =>
    createStyles({
      appBar: {
        backgroundColor: whiteColor + ' !important',
        color: primaryColor + ' !important',
        minHeight: topBarHeight + '!important',
        maxHeight: topBarHeight + '!important',
        boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.1) !important',
        [theme.breakpoints.down('sm')]: {
          minHeight: mobileTopBarHeight + '!important',
          maxHeight: mobileTopBarHeight + '!important',
        },
      },
      appBarRelative: {
        display: 'block',
        minHeight: topBarHeight + '!important',
        maxHeight: topBarHeight + '!important',
        [theme.breakpoints.down('sm')]: {
          minHeight: mobileTopBarHeight + '!important',
          maxHeight: mobileTopBarHeight + '!important',
        },
      },
      topBar: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: topBarHeight + '!important',
        maxHeight: topBarHeight + '!important',
        [theme.breakpoints.down('sm')]: {
          minHeight: mobileTopBarHeight + '!important',
          maxHeight: mobileTopBarHeight + '!important',
        },
      },
      topBarContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        '@media (max-width: 959px)': {
          padding: 0
        }
      },
      seloCentralAtendimento: {
        marginLeft: theme.spacing(1),
      },
      brandButton: {
        color: 'initial !important',
      },
      brand: {
        fontSize: '48px !important',
        [theme.breakpoints.down('sm')]: {
          fontSize: '32px !important',
        },
      },
      menuButtonContainer: {
        position: 'relative',
        width: '0',
      },
      menuButton: {
        position: 'absolute',
        top: '50%',
        right: 0,
        transform: 'translateY(-50%)',
      },
      menuIcon: {
        fontSize: theme.spacing(4),
      },
    }),
);